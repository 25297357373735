h1.maps {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--first-color);
  z-index: 10;
  margin: 0;
  padding: 0;
}

.map-search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.map-search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}

.mbl-link {
  display: none;
}

.backlink {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 5px;
  display: inline-block;
  color: var(--button-color);
}

span.mp_det_hd {
  font-weight: bold !important;
  color: var(--first-color);
}

div.img-cont {
  width: 5%;
}

img.map-img {
  width: 200px !important;
}

.projectSuggest {
  padding: 0rem;
  font-weight: 500;
  background-color: #fff;

  li {
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.5rem;
  }
}

.searchDiv {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border: 1px solid black;

  .srch_in {
    border: 0;
  }

  .srch_btn {
    margin: 0 0.5rem;
  }
}

@media only screen and (max-width: 760px) {
  .search {
    width: 80%;
  }

  .search input {
    padding: 0.4rem;
    font-size: 1rem;
  }

  .locate {
    right: 0.5rem;
    top: 1.5rem;
  }

  .locate img {
    width: 25px;
  }

  h1.maps {
    display: none;
  }

  .mbl-link {
    display: block;
    position: absolute;
    font-weight: 600;
    color: var(--button-color);
    z-index: 10;
    font-size: 1.8rem;
    left: 0.5rem;
    top: 1rem;
  }

  .backlink {
    display: none;
  }
}
