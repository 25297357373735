.angry-grid {
  display: grid;
  padding: 1rem;

  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  gap: 0px;
  height: 100%;
  width: 80%;
  align-items: center;
  justify-content: center;
}

h1 {
  color: var(--text-color);
  font-weight: 400;
  font-size: 1.8rem;
}

.angry-grid img {
  display: none;
}

.angry-grid > * {
  padding: 2rem;
}

#item-0 {
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 4;
  grid-column-end: 2;
  border-right: 1px solid #ccc;
}

#item-0 img {
  display: inline-block;
  margin: 1rem auto;
  width: 90%;
}

#item-1 {
  grid-row-start: 3;
  grid-column-start: 2;

  grid-row-end: 4;
  grid-column-end: 3;
}
#item-2 {
  grid-row-start: 1;
  grid-column-start: 2;

  grid-row-end: 2;
  grid-column-end: 3;
  border-bottom: 1px solid #ccc;
}
#item-3 {
  grid-row-start: 2;
  grid-column-start: 2;

  grid-row-end: 3;
  grid-column-end: 3;
  border-bottom: 1px solid #ccc;
}

p.date {
  color: var(--first-color);
  margin-top: 1rem;
}

p.read {
  color: var(--title-color1);
}

@media only screen and (max-width: 1350px) {
  .angry-grid {
    width: 90%;
  }
}

@media only screen and (max-width: 1150px) {
  .angry-grid {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .angry-grid {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }
  .angry-grid > * {
    padding: 0.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  #item-0 {
    border-bottom: 1px solid #ccc;
    border-right: 0;
  }
  #item-0 img {
    display: inline-block;
    margin: 1rem auto;
    width: 100%;
  }
  #item-1 {
    border-bottom: 1px solid #ccc;
  }
}
