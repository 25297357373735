div.footmark {
  padding: 0.2rem;
  text-align: left;
  border: 0;
  background-color: var(--first-color);
}

div.footmark p {
  font-size: 0.9rem;
  color: var(--white-text);
}
