/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Montserrat:wght@400;700&display=swap"); */

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  --first-color: #0e9e69;
  --first-color-light: #19b47b;
  --second-color: #4affbd;
  --third-color: #2deba5;
  --fourth-color: #9e1d00;
  --fifth-color: #f05532;
  --text-color: #7d7c7a;
  --title-color: #9e1d00;
  --title-color1: #b5a014;
  --text-color-darker: hsl(0, 0%, 35%);
  --text-color-light: hsl(0, 0%, 75%);
  --text-color-dark: hsl(30, 75%, 30%);
  --light-bg: rgb(250, 250, 250);
  --white-text: #fff;
  --black-text: #000;
  --body-color: hsl(0, 0%, 99%);
  --container-color: #fff;
  --border-color: hsl(0, 0%, 94%);

  /*========== Font and typography ==========*/
  --body-font: "Roboto", sans-serif;
  --biggest-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-bold: 700;

  /*========== Margenes Bottom ==========*/
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 2.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  margin: 0;
  background-color: var(--body-color);
  color: var(--text-color);
  /*For animation dark mode*/
  transition: 0.4s;
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

ul {
  list-style: none;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}
