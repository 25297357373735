nav {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
nav .navbar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: auto;
  /* height: 5rem; */
  /* background: red; */
  /* padding: 0 50px; */
}
.navbar .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px dashed lavender;
  position: relative;
}
.navbar .logo p {
  color: var(--fourth-color);
  margin-bottom: 1rem;
  font-weight: 500;
  text-align: center;
}
.navbar .logo img {
  float: left;
  max-height: 100px;
  margin: 0.3rem 0;
}
nav .navbar .nav-links {
  line-height: 65px;
  padding-left: 3%;
  background-color: var(--first-color);
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}
nav .navbar .links {
  display: flex;
}
nav .navbar .links li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 1rem;
}
nav .navbar .links li .NavLink {
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
nav .navbar .links li a {
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
.links li:hover .htmlcss-arrow,
.links li:hover .about-arrow,
.links li:hover .js-arrow {
  transform: rotate(180deg);
}

nav .navbar .links li .arrow {
  /* background: red; */
  height: 100%;
  width: 22px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  color: #fff;
  transition: all 0.3s ease;
}
nav .navbar .links li .sub-menu {
  position: absolute;
  top: 65px;
  left: 0;
  line-height: 40px;
  background: var(--first-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
}
nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .about-sub-menu,
nav .navbar .links li:hover .js-sub-menu {
  display: block;
}
.navbar .links li .sub-menu li {
  padding: 0 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.navbar .links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
.navbar .links li .sub-menu .more span {
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar .links li .sub-menu .more-arrow {
  line-height: 40px;
}
.navbar .links li .htmlCss-more-sub-menu {
  line-height: 40px;
}
.navbar .links li .about-more-sub-menu {
  line-height: 40px;
}
.navbar .links li .sub-menu .more-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 100;
  display: none;
}
.links li .sub-menu .more:hover .more-sub-menu {
  display: block;
}
.navbar .search-box {
  position: relative;
  height: 40px;
  width: 40px;
}
.navbar .search-box i {
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 40px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.navbar .search-box .input-box {
  position: absolute;
  right: calc(100% - 40px);
  top: 80px;
  height: 60px;
  width: 300px;
  background: var(--first-color);
  border-radius: 6px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
}
.navbar.showInput .search-box .input-box {
  top: 65px;
  opacity: 1;
  pointer-events: auto;
  background: var(--first-color);
}
.search-box .input-box::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background: var(--first-color);
  right: 10px;
  top: -6px;
  transform: rotate(45deg);
}
.search-box .input-box input {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 280px;
  outline: none;
  padding: 0 15px;
  font-size: 16px;
  border: none;
}
.navbar .nav-links .sidebar-logo {
  display: none;
}
.navbar .bx-menu {
  display: none;
}
@media (max-width: 920px) {
  nav .navbar {
    max-width: 100%;
    padding: 0 25px;
  }

  nav .navbar .logo a {
    font-size: 27px;
  }
  nav .navbar .links li {
    padding: 0 10px;
    white-space: nowrap;
  }
  nav .navbar .links li a {
    font-size: 15px;
  }
}
@media (max-width: 800px) {
  nav {
    position: relative;
  }
  nav .navbar {
    height: 100%;
    max-width: 100%;
    padding: 0 5px;
  }
  .navbar .bx-menu {
    display: block !important;
    height: 2.5rem;
    width: 2.5rem;
    float: right;
    background-color: var(--first-color);
    color: #fff;
    padding: 4px 5px;
    border-radius: 5px;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    z-index: 10037;
    color: #fff;
  }
  .navbar .logo img {
    float: left;
    max-height: 50px;
    margin: 0.1rem 0;
  }
  nav .navbar .nav-links {
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    max-width: 270px;
    width: 100%;
    background: var(--first-color);
    line-height: 40px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    z-index: 1000;
  }
  nav .navbar .nav-links-show {
    position: fixed;
    top: 2rem;
    left: 0;
    display: block;
    max-width: 320px;
    width: 100%;
    background: var(--first-color);
    line-height: 40px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    z-index: 1000;
  }
  .navbar .nav-links .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-logo .logo-name {
    font-size: 25px;
    color: #fff;
  }
  .sidebar-logo i,
  .navbar .bx-menu {
    font-size: 25px;
    color: #fff;
  }
  nav .navbar .links {
    display: block;
    margin-top: 20px;
  }
  nav .navbar .links li .arrow {
    line-height: 40px;
  }
  nav .navbar .links li {
    display: block;
  }
  nav .navbar .links li .sub-menu {
    position: relative;
    top: 0;
    box-shadow: none;
    display: none;
  }
  nav .navbar .links li .sub-menu li {
    border-bottom: none;
  }
  .navbar .links li .sub-menu .more-sub-menu {
    display: none;
    position: relative;
    left: 0;
  }
  .navbar .links li .sub-menu .more-sub-menu li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .links li:hover .htmlcss-arrow,
  .links li:hover .about-arrow,
  .links li:hover .js-arrow {
    transform: rotate(0deg);
  }
  .navbar .links li .sub-menu .more-sub-menu {
    display: none;
  }
  .navbar .links li .sub-menu .more span {
    /* background: red; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .links li .sub-menu .more:hover .more-sub-menu {
    display: block;
  }
  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .about-sub-menu,
  nav .navbar .links li:hover .js-sub-menu {
    display: block;
  }
  nav .navbar .links li:hover .htmlCss-sub-menu-show,
  nav .navbar .links li:hover .about-sub-menu-show,
  nav .navbar .links li:hover .js-sub-menu-show {
    display: block;
  }
  .navbar .nav-links.show1 .links .htmlCss-arrow,
  .navbar .nav-links.show1 .links .about-arrow,
  .navbar .nav-links.show3 .links .js-arrow {
    transform: rotate(180deg);
  }
  .navbar .nav-links.show2 .links .more-arrow {
    transform: rotate(90deg);
  }
}
@media (max-width: 370px) {
  nav .navbar .nav-links {
    max-width: 100%;
  }
}
